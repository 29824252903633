<template>
  <div class="w-100">
    <div class="title-actions">
      <h5 class="title">{{ tournament.name }} - {{ $t('notices') }} - {{ $t('edit') }}</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-form @submit.prevent="save">
      <div class="form-group">
        <label for="title">{{ $t('title') }}</label>
        <b-form-input v-validate="'required|max:125'" name="title" id="title" v-model="notice.title" :state="validateState('title')" />
        <span class="error-inputs">{{ errors.first('title') }}</span>
      </div>
      <div class="form-group">
        <label for="content">{{ $t('content') }}</label>
        <quill-editor ref="myTextEditor" name="content" v-model="notice.content" :options="editorOption" v-validate="'required'" :state="validateState('quantity')"></quill-editor>
        <span class="error-inputs">{{ errors.first('content') }}</span>
      </div>
      <div class="form-group">
        <label for="file">{{ $t('image') }} - <small class="text-muted">*.png, *.jpg, *.jpeg | 1024 x 500px</small></label>
        <div class="custom-file" :class="validateState('language') ? 'is-valid' : 'is-invalid'">
          <input type="file" ref="inputFile" class="custom-file-input" id="file" :lang="lang" @change="imagePreview($event)" v-validate="'image|ext:png,jpg,jpeg|size:1024'" accept=".png,.jpg,.jpeg" name="files" :data-vv-as="$t('image') | toLowerCase">
          <label class="custom-file-label" ref="lblFileName" for="file">{{ $t('findImage') }}</label>
        </div>
        <img v-if="imgPath" :src="imgPath" alt="Vista previa" class="img-fluid img-thumbnail mt-3">
      </div>
      <div class="form-group">
        <b-form-checkbox v-model="notice.show" name="check-button" switch>{{ $t('show') }}</b-form-checkbox>
      </div>
      <div class="form-group">
        <router-link class="btn btn-secondary mr-3" :to="{ name: 'TournamentBackNotices', params: { lang: lang, console: console, tournamentId: tournament.id } }">{{ $t('return') }}</router-link>
        <b-button type="submit" variant="primary">{{ $t('submit') }}</b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      notice: {},
      editorOption: {
        modules: {
          toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['clean'],
              ['link', 'image', 'video']
            ],
           imageDrop: true,
           imageResize: true
         },
         theme: 'snow'
      },
      file: null,
      imgPath: null
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'tournament',
      'user',
      'console'
    ])
  },
  mounted() {
    if (!this.tournament.name) {
      this.$router.push({ name: 'Tournaments', params: { lang: this.lang, console: this.console } })
      return
    }
    this.changeTitleBar(this.tournament.name + ' - ' + this.$t('notices') + ' - ' + this.$t('edit'))
    this.fetchData()
  },
  methods: {
    fetchData () {
      const tournamentId = this.$route.params.tournamentId
      const noticeId = this.$route.params.noticeId
      const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/notices/${noticeId}/edit`
      this.$axios.get(path).then(response => {
        this.notice = response.data.data
        this.imgPath = response.data.data.image
      })
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = new FormData()
          payload.append('title', this.notice.title)
          payload.append('content', this.notice.content)
          payload.append('show', this.notice.show ? 1 : 0)
          if (this.file) {
            payload.append('image', this.file)
          }
          const tournamentId = this.$route.params.tournamentId
          const noticeId = this.$route.params.noticeId
          const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/notices/${noticeId}/update`
          this.$axios.post(path, payload).then(() => {
            this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
            this.$router.push({ name: 'TournamentBackNotices', params: { lang: this.lang, console: this.console, tournamentId } })
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    imagePreview (e) {
      const input = e.target  
      this.file = input.files[0]
      const _self = this
      if (input.files && input.files[0]) {
        _self.$refs.lblFileName.innerHTML = _self.file.name
        var reader = new FileReader()
        reader.onload = function (e) {
          _self.imgPath = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
        _self.file = null
        _self.$refs.lblFileName.innerHTML =  _self.$t('findImage')
      }
    }
  }
}
</script>
<style>
.ql-editor{
  min-height: 200px;
}
</style>
